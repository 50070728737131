import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    paper: {
      padding: '6px 16px',
      backgroundColor: "rgba(200, 200, 200, 0)",
      boxShadow: "none"
    },
    title: {
      color: "white",
      marginBottom: "1rem",
      fontSize: "1.5rem",
      fontWeight: 300
    },
    date: {
      color: "rgba(45, 192, 237, 1)",
      marginBottom: "0.2rem",
      fontWeight: 550,
      letterSpacing: "1px"
    },
    subheading: {
      color: "white",
      marginBottom: "0.2rem",
      fontWeight: 300,
      fontSize: "1rem",
    },
    description: {
      color: 'rgba(200, 200, 200, 1)',
      fontWeight: 300,
      fontSize: "0.9rem"
    }
  }));

function TimelineCard(props){
    const classes = useStyles();

    return (
        <Paper elevation={3} className={classes.paper}>
          <Typography className={classes.date}>{props.content.date}</Typography>
          <Typography variant="h6" className={classes.title}>{props.content.title}</Typography>
          <Typography className={classes.subheading}>{props.content.subheading}</Typography>
          <Typography className={classes.description}>{props.content.description}</Typography>
        </Paper>
    )
}

export default TimelineCard;