import React from 'react'
import HeadingComponent from './HeadingComponent';
import PortfolioCard from './PortfolioCard';
import '../styles/PortfolioSection.css'

function PortfolioSection(props){
    const imageUrl = "https://images.unsplash.com/photo-1551120721-113cbf83166b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2767&q=80";
    
    const content = [
      {
        title: "Portfolio site",
        titleLink: "",
        imageUrl: process.env.PUBLIC_URL + "/my-site-img.png",
        description: [
          {title: "Background",
            text: 
            `This site was created from scratch with React, with inspiration from a few templates I found on the fabled website builders Wix & Squarespace and images from Unsplash. 
            `
          },
          {title: "Story of Creation",
            text: 
            `Some components are completely custom (e.g. horizontal bar chart), and some are highly customized components borrowed from material-ui (appbar, modal, timeline). For animations on appearance of elements I used the Interaction Observer api, for which I found a wonderful React hook (linked below).
            `
          }
        ],
        extraInfoTitle: "Tools",
        extraInfoContent: "React",
        externalLinksTitle: "Links",
        externalLinks: [
          {text: "Unsplash", link: "https://unsplash.com/"},
          {text: "Material-ui", link: "https://material-ui.com"},
          {text: "Interaction Observer Hook", link: "https://medium.com/the-non-traditional-developer/how-to-use-an-intersectionobserver-in-a-react-hook-9fb061ac6cb5"}
        ]
      },
      {
        title: "Wikiplus.io",
        titleLink: "https://wikiplus.io/about",
        imageUrl: process.env.PUBLIC_URL + "/wikiplus.gif",
        description: [
          {title: "Background",
            text: 
            `WikiPlus.io is a free wikipedia reader with highlighting and text to speech in a medium-like package. This was my first React project outside of courses.
            `
          },
          {title: "Story of Creation",
            text: 
            `The core of this was rather simple: use the WikiMedia api and parse the output into a more pleasing package with custom styles. 
            
            Highlighting was more challenging than intially thought becuase Wikipedia articles often change, so highlights are likely to change too for popular articles. To get around this I created a highlight recovery feature that automatically finds these and repositions them within the article, and notifies the user to which ones could not be recovered. For the fundamental workings of highlighting I tried a number of things on my own, but eventually settled on a package called web-highlighter which worked well with my recovery feature. 
            
            Text-to-speech was done with the SpeechSynthesis api, which worked well for the most part (some bugs required work-arounds, such as limiting lengths of each utterance). 
            
            For persistence of highlights (& bookmarks) I used Firebase, with the (more or less) built-in auth capabilities. I also implemented pagination and made WikiPlus an installable pwa, with a pop-up when new versions are available. Some of the UI elements are from Material-ui, with some customisation. The demo video shows the main features (though note the ui has been tweaked since this was taken).
             `,
          }
        ],
        extraInfoTitle: "Tools & Libraries",
        extraInfoContent: "React, Redux, Firebase",
        externalLinksTitle: "Links",
        externalLinks: [
          {text: "Wikiplus.io", link: "https://Wikiplus.io"},
          {text: "Demo on Youtube", link: "https://www.youtube.com/watch?v=OmIjQlVMqJo"},
          {text: "Web-highlighter", link: "https://github.com/alienzhou/web-highlighter"},
          {text: "Speech synthesis api", link: "https://developer.mozilla.org/en-US/docs/Web/API/SpeechSynthesis"},
          {text: "Wikipedia api", link: "https://www.mediawiki.org/wiki/Wikimedia_REST_API"}
        ]
      },
      {
        title: "Well visualisation",
        imageUrl: "https://images.unsplash.com/photo-1590479773265-7464e5d48118?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2700&q=80",
        description: [
        {title: "Background",
            text: 
            `This is a web-app I created in my role at Shell, where I noticed a disconnect between data we had and quick interpreation of it. 
            `
        },
        {title: "Extra Context",
            text: 
            `To extract oil you have to drill holes (known as wells) underground, through many layers of rock (known as formations, that formed over millions of years), until you finally reach oil-bearing rock. These oil-bearing rocks are often many miles wide and there could be multiple oil bearing rocks in a particular location (collectively known as a field). To extract the oil effectively from a field you need to drill many wells.
            These wells are typically thousands of feet deep and they usually contain many artifacts such as blockages (deliberate or not) and can contain tiny holes (known as perforations) at the oil-bearing rock, so that oil can flow into the well. Complex modelling software exists, however these are usually large models that require specialised computers and therefore are limited in access and speed. The lightweight web-app I created allows the user to import an Excel file of the data and then view & interact with it in the browser by clicking on elements, dragging them etc (see the GIF, tbc). The diagram is accurate for depth and I also added a function to automatically interpolate formations between wells.
            `
        },
        {title: "Story of Creation",
          text: 
            ` At the time, I had just finished a web-development course and knew basic DOM manipulation, so wrote it entirely with vanilla JS, HTML & CSS. With the knowledge I have now, particularly the knowledge that React exists, this was clearly a terrible idea. It was a rather enlightening experience to say the least, as almost all of the content was dynamic so I wrote a number of functions to manipulate the DOM and variables. 
            I also needed a way of keeping track of changes made by the user so made use of a global variable to store this, which I then printed to a text file when the user hit save (also loading via this method). Finally, I also needed a way of causing rerenders when a user changed something so had a method to redraw the diagram when needed. Looking back, this was amusing as it was almost like a fake and extremely basic React (the functions were almost like components, the state was this global variable). Luckily, this makes the app not too difficult to translate into React, which will be far more maintainable going forward from MVP stage, along with adding features (e.g. database) and polishing.
            `
        }
        ],
        extraInfoTitle: "Tools",
        extraInfoContent: "Vanilla js",
        externalLinksTitle: "Links",
        externalLinks: [
          {text: "Excel parser (SheetJS)", link: "https://github.com/SheetJS/sheetjs"}
        ]
      },
      {
        title: "Lights Out",
        titleLink: "/lights-out",
        imageUrl: process.env.PUBLIC_URL + "/lights-out.png",
        description: [
          {title: "Background",
            text: 
            `The objective of the game is to turn all the lights (blocks) off, hence the name Lights out. Watch out however, as if you click a block, the blocks around it will flip - just like the original 1995 game! 
            `
          },
          {title: "Story of creation",
            text: 
            `I made this small project as part of a course I took to learn React. It was one of the first I created with React, and was created with class-based components.
            `
          }
        ],
        extraInfoTitle: "Tools",
        extraInfoContent: "React",
        externalLinksTitle: "Links",
        externalLinks: [
          {text: "Demo", link: "/lights-out"},
          {text: "React bootcamp", link: "https://www.udemy.com/course/modern-react-bootcamp/"}
        ]
      },
      {
        title: "Minimal Calculator",
        titleLink: "https://apps.apple.com/gb/app/minimal-calculator/id1563970270",
        imageUrl: process.env.PUBLIC_URL + "/App-store-screenshots.png",
        description: [
          {title: "Background",
            text: 
            `A project to hone the skills I learnt during an iOS development course. 
            
            The app is a more fully featured iOS calculator, with creative use of UIKit and other apis.
            
            It allows the calculation to be seen and edited, history, emojis as memory, conversions and more.
            `
          }
        ],
        extraInfoTitle: "Tools",
        extraInfoContent: "Swift",
        externalLinksTitle: "Links",
        externalLinks: [
          {text: "App store page", link: "https://apps.apple.com/gb/app/minimal-calculator/id1563970270"}
        ]
      }
    ]

    return (
        <div id={props.id} className="PortfolioSection" style={{backgroundImage: `url(${imageUrl})`}}>
          <HeadingComponent
            number="02" 
            type="secondary"
            title="Portfolio"
            subHeading={"Click to find out more"}
          />

          <div className="PortfolioSection-row">
            {content.map((item, idx) => 
              <div className="PortfolioSection-item" key={idx}>
                <PortfolioCard content={item}/>
              </div>
            )}
          </div>

        </div>
    )
}

export default PortfolioSection;