import React from 'react'
import HeadingComponent from './HeadingComponent';
import HorizontalBarChart from './HorizontalBarChart';
import '../styles/CompetencySection.css';

function CompetencySection(){
    const imageUrl = "https://images.unsplash.com/photo-1572587356426-b33bf42f999b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2702&q=80";

    const chartData = [
      {value: 85, title: "React", image: "https://cdn2.iconfinder.com/data/icons/designer-skills/128/react-512.png"},
      {value: 90, title: "JavaScript", image: "https://cdn2.iconfinder.com/data/icons/designer-skills/128/code-programming-javascript-software-develop-command-language-512.png"},
      {value: 90, title: "HTML", image: "https://cdn0.iconfinder.com/data/icons/HTML5/512/HTML_Logo.png"},
      {value: 90, title: "CSS", image: "https://cdn1.iconfinder.com/data/icons/logotypes/32/badge-css-3-512.png"},
      {value: 90, title: "Python", image: "https://cdn4.iconfinder.com/data/icons/logos-and-brands/512/267_Python_logo-512.png"},
      {value: 85, title: "Swift", image: "https://cdn4.iconfinder.com/data/icons/logos-and-brands/512/332_Swift_logo-512.png"},
      {value: 80, title: "MATLAB", image: "https://upload.wikimedia.org/wikipedia/commons/2/21/Matlab_Logo.png"},
      {value: 75, title: "Node.js", image: "https://cdn3.iconfinder.com/data/icons/popular-services-brands/512/node-512.png"}
    ]


    return (
      <div id="CompetencySection" className="CompetencySection" style={{backgroundImage: `url(${imageUrl})`}}>
        <HeadingComponent
            number="01" 
            type="secondary"
            title="Skills"
            subHeading={"Competency Level in Languages & tools"}
          />
          <div className="CompetencySection-ChartContainer">
            <HorizontalBarChart data={chartData} showTitle={true}/>
          </div>
          
      </div>
    )
}

export default CompetencySection;