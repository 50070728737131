import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineCard from './TimelineCard'
import useIntersect from '../hooks/useIntersect'

const useStyles = makeStyles((theme) => ({
  timeline: {
    width: "70%",
    "@media(max-width: 700px)": {
      width: "90%"
    }
  },
  item: {
    animationName: "$fade",
    animationTimingFunction: "cubic-bezier(0.55, 0.055, 0.675, 0.19)",
    '-webkit-transform': "translate3d(0, 0, 0)"  // to stop flicker
  },
  '@keyframes fade': {
    from: {opacity: 0},
    to: {opacity: 1}
  },
  tail: {
    backgroundColor: "rgba(45, 192, 237, 1)",
    height: "200px"
  },
  dot: {
    backgroundColor: " rgba(45, 192, 237, 1)",
    margin: 0
  },
}));

function TimelineComponent(props) {
  const classes = useStyles();
    
  const [ref, entry] = useIntersect({
        threshold: [0.1]
  });

  const [alignment, setAlignment] = useState("alternate");

  function handleResize() {
    if(window.innerWidth <= 500){
      setAlignment("left")
    } else{
      setAlignment("alternate")
    }
  }
  
  useEffect(() => {
    handleResize(); // make sure runs on first load

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  });

  return (
    <Timeline id={props.id} align={alignment} ref={ref} className={classes.timeline}>
      {entry.intersectionRatio > 0 && 
        props.content.map((item, idx) => 
            <TimelineItem style={{ animationDuration: `${0.5 * (idx+1)}s` }} className={classes.item} key={idx}>
          
              <TimelineSeparator>
                <TimelineDot className={classes.dot}/>
                {(idx < props.content.length - 1) && <TimelineConnector className={classes.tail}/>}
              </TimelineSeparator>

              <TimelineContent>
                <TimelineCard content={item}/>
              </TimelineContent>
        
            </TimelineItem>
      )}
    </Timeline>
  );
}

export default TimelineComponent;