import React from 'react'
import useIntersect from '../hooks/useIntersect'
import '../styles/HorizontalBarChart.css'

function HorizontalBarChart(props){
    const [ref, entry] = useIntersect({
        threshold: [0.1]
      });
    
    const values = props.data.map(item => item.value);
    const maxValue = Math.max(...values);
    
    return(
        <div className="HorizontalBarChart" ref={ref}>
            {props.data.map((item, idx) => 
                <div className="HorizontalBarChart-item" key={idx}>
                    <div className="HorizontalBarChart-item-label">
                        {props.showTitle && item.title && <p className="HorizontalBarChart-item-label-title">{item.title}</p>}
                        {item.image && <img className="HorizontalBarChart-item-label-image" src={item.image} alt={item.title}/>}
                    </div>
                    {entry.intersectionRatio > 0 &&
                        <div className="HorizontalBarChart-bar-container" style={{ animationDuration: `${0.5 * (idx+1)}s` }}>
                            <div className="HorizontalBarChart-bar" style={{width: `${100*item.value/maxValue}%`}}>
                                <div className="HorizontalBarChart-bar-value">{`${item.value}%`}</div>
                            </div>
                        </div>
                    }
                    
                </div>
            )}
        </div>
    )
}

export default HorizontalBarChart;