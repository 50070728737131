import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
    modal: {
      height: "100%",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    modalContent: {
        position: "relative",
        height: "90%",
        width: "90%",
        display: "flex",
        flexDirection: "row",
        outline: "none",
        fontFamily: "sans-serif",
        fontWeight: 300,
        color: "rgba(100, 100, 100, 0.7)",
        "@media(max-width: 800px)": {
            flexDirection: "column"
        }
    },
    imageSection: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "50%",
        backgroundColor: " rgba(70, 70, 70, 1)",
        borderRight: "1px solid rgba(100, 100, 100, 0.3)",
        "@media(max-width: 800px)": {
            width: "100%",
            height: "50%"
        }
    },
    title: {
        color: "rgba(250, 250, 250, 0.9)",
        fontWeight: 100,
        fontSize: "1rem",
        marginTop: "0px",
        textTransform: "uppercase",
        textDecoration: "underline"
    },
    image: {
        height: "auto",
        maxHeight: "80%",
        margin: "30px",
        marginBottom: "5px",
        width: "85%",
        objectFit: "cover",
        overflow: "hidden",
        "@media(max-width: 800px)": {
            width: "80%",
            height: "auto"
        }
    },
    descriptionSection: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        margin: "0 auto",
        padding: "30px 0",
        alignItems: "center",
        textAlign: "center",
        width: "50%",
        height: "100%",
        backgroundColor: "white",
        overflowY: "scroll",
        '*&::-webkit-scrollbar': {
            display: "none" // Chrome, Safari, Opera
        },
        '-ms-overflow-style': "none", // IE, Edge
        'scrollbar-width': "none", // Firefox
        "@media(max-width: 800px)": {
            width: "100%",
            height: "50%",
            justifyContent: "flex-start"
        }
    },
    descriptionContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "auto",
        width: "100%",
        // justifyContent: "center",
        // flexGrow: 1
    },
    description: {
        display: "flex",
        alignItems: "center",
        width: "90%",
        minHeight: "max-content",
        whiteSpace: "pre-line",
        marginTop: 0
    },
    extraInfoTitle: {
        width: "100%",
        fontWeight: 500,
        color: "rgba(100, 100, 100, 1)",
        margin: 0,
        padding: 0
    },
    extraInfoContent: {
        width: "90%",
        marginTop: 0,
    },
    externalLinks: {
        width: "100%"
    },
    externalLink: {
        color: "black",
        margin: "0 5px",
        paddingBottom: "5px"
    },
    cross: {
        position: "absolute",
        top: "5px",
        right: "5px",
        fontSize: "2rem",
        "&:hover": {
            color: " rgba(45, 192, 237, 1)"
        },
        "@media(max-width: 800px)": {
            color: "white",
            fontSize: "1.3rem"
        }
    }
  }));

function PortfolioModal(props){
    const classes = useStyles();

    return(
        <Modal
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.modal}
        >   
        <div className={classes.modalContent}>
            <div className={classes.imageSection}>
                <a href={props.content.titleLink}>
                    <h2 className={classes.title}>{props.content.title}</h2>
                </a>
                <a href={props.content.titleLink}>
                    <img className={classes.image} src={props.content.imageUrl} alt={props.content.title}></img>
                </a>
            </div>
            <div className={classes.descriptionSection}>
                <div className={classes.descriptionContainer}>
                    {props.content.description.map((item, idx) => 
                        <React.Fragment key={idx}>
                            <p className={classes.extraInfoTitle}>{item.title}</p>
                            <p className={classes.description}>{item.text}</p>
                        </React.Fragment>
                    
                    )}

                <p className={classes.extraInfoTitle}>{props.content.extraInfoTitle}</p>
                    <p className={classes.extraInfoContent}>{props.content.extraInfoContent}</p>
                    {props.content.externalLinks && 
                        <div className={classes.externalLinks}>
                            <p className={classes.extraInfoTitle}>{props.content.externalLinksTitle}</p>
                            {props.content.externalLinks.map((item, idx) =>
                                <a key={idx} className={classes.externalLink} href={item.link}>{item.text}</a>
                            )}
                        </div>
                    }
                </div>
                
            </div>

            <CloseIcon className={classes.cross} onClick={props.onClose}/>

        </div>
        </Modal>
    )
}

export default PortfolioModal;