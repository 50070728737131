import React from 'react'
import HeadingComponent from './HeadingComponent';
import '../styles/ContactSection.css'

function ContactSection(props){
    const imageUrl = "https://images.unsplash.com/photo-1495474472287-4d71bcdd2085?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2700&q=80";

    return (
        <div id={props.id} className="ContactSection" style={{backgroundImage: `url(${imageUrl})`}}>
          <HeadingComponent
            number="04" 
            type="secondary"
            title="Contact"
          />

          <div className="ContactSection-content">

            <div className="ContactSection-content-item">
              <p>
                If you'd like to contact me regarding work or volunteering opportunities please get in touch via LinkedIn, where I will provide my CV on request.
              </p>
              <p>
                P.S. Alternatively if you would just like to share ideas/advice I'm more than willing to have a chat. 
                I'm a firm believer in helping those who ask.
              </p>
            </div>

            <a className="ContactSection-content-item" href="https://uk.linkedin.com/in/ummer-taahir-290ba7171">
              <img className="ContactSection-connect-image" src={process.env.PUBLIC_URL + "/me.png"} alt="Ummer Taahir"/>
              <img className="ContactSection-connect-logo" src="https://cdn2.iconfinder.com/data/icons/black-white-social-media/32/linked_in_online_social_media-512.png" alt="LinkedIn logo"/>
            </a>
          </div>

        </div>
    )
}

export default ContactSection;


