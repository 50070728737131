import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

const useStyles = makeStyles((theme) => ({
    appbar: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        backgroundColor: "rgba(200, 200, 200, 0.1)",
        boxShadow: "none"
    },
    toolbar: {
      "@media(max-width: 500px)": {
        padding: 0,
        margin: "0 auto"
      }
      
    },
    navButton: {
        color: "white"
    }
}))

function Navbar(props) {
    const classes = useStyles();

    function handleClick(anchorId){
        let element = document.getElementById(anchorId);
        window.scrollTo(0, element.offsetTop);
    }

  return (
    <React.Fragment >
      <CssBaseline />
      <HideOnScroll {...props}>
        <AppBar className={classes.appbar}>
          <Toolbar className={classes.toolbar}>
              {props.items.map((item, idx) => 
                    <Button key={idx} className={classes.navButton} onClick={() => handleClick(item.anchorId)}>
                        {item.name}
                    </Button>
                )}
          </Toolbar>
        </AppBar>
      </HideOnScroll>
    </React.Fragment>
  );
}

export default Navbar;