// package imports
import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';

// component imports
import Navbar from './components/Navbar'
import TitleSection from './components/TitleSection'
import CompetencySection from './components/CompetencySection'
import PortfolioSection from './components/PortfolioSection'
import TimelineSection from './components/TimelineSection'
import ContactSection from './components/ContactSection'
import './styles/App.css';

// mini-app imports
import LightsOut from './Lights-out/App'

function App() {
  
  const items = [
    {name: "Skills", anchorId: "CompetencySection"},
    {name: "Portfolio", anchorId: "PortfolioSection"},
    {name: "Experience", anchorId: "TimelineSection"},
    {name: "Contact", anchorId: "ContactSection"}
  ]

  return (
    <div className="App">
      <Switch>
            <Route exact path="/lights-out">
              <LightsOut />
            </Route>
            <Route exact path="/">
              <Navbar items={items}/>
              <TitleSection id="TitleSection"/>
              <CompetencySection id="CompetencySection"/>
              <PortfolioSection id="PortfolioSection"/>
              <TimelineSection id="TimelineSection"/>
              <ContactSection id="ContactSection"/> 
            </Route>
          {/* Fallback redirect*/}
          <Redirect to="/"></Redirect>
      </Switch>

        
    </div>
  );
}

export default App;
