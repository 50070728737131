import React from 'react'
import HeadingComponent from './HeadingComponent';
import TimelineComponent from './TimelineComponent';
import '../styles/TimelineSection.css'

function TimelineSection(props){
    const imageUrl = "https://images.unsplash.com/photo-1592613824182-cf1790a5a17d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2569&q=80";
    const content =[
      {title: "Shell", date: "Sept 20 - Present" , subheading: "Energy Analyst", description: "Strategy & portfolio, Energy Team. Involved in quantifying the narratives that describe future energy systems."},
      {title: "Shell", date: "Sept 19 - Sept 20" , subheading: "Petroleum Engineer", description: "Graduate programme in upstream oil and gas fields for the UK, specifically in the Reservoir Engineering sub-discipline."},
      {title: "University of Cambridge", date: "Oct 15 - Jun 19" , subheading: "MEng. BA, Chemical Engineering", description: "First class (4.0 GPA equiv). Master's dissertation of creation and optimisation of chemical reaction networks."},
      {title: "Siemens", date: "Aug 18 - Sept 18" , subheading: "Engineering Intern", description: "Summer internship focused on process improvement for manufacture of hospital blood testing machines."}
    ]

    return (
        <div id={props.id} className="TimelineSection" style={{backgroundImage: `url(${imageUrl})`}}>
          <HeadingComponent
            number="03" 
            type="secondary"
            title="Experience"
          />
          <TimelineComponent content={content}/>
        </div>
    )
}

export default TimelineSection;