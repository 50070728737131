import React from 'react'
import HeadingComponent from './HeadingComponent';
import '../styles/TitleSection.css'

function TitleSection(props){
    const imageUrl = "https://images.unsplash.com/photo-1464821541677-ceb53bcf1541?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80";

    return (
        <div id={props.id} className="TitleSection" style={{backgroundImage: `url(${imageUrl})`}}>
          <HeadingComponent
            type="main"
            title="Ummer Taahir"
            period={true}
            subHeading={"Engineer & Software Developer"}
          />
      </div>
    )
}

export default TitleSection;