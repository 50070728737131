import React, {useState}  from 'react'
import PortfolioModal from './PortfolioModal'
import '../styles/PortfolioCard.css'

function PortfolioCard(props){
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };

    return(
        <div className="PortfolioCard">
            <img onClick={handleOpen} src={props.content.imageUrl} alt={props.content.title}/>
            <p className="PorfolioCard-caption">{props.content.title}</p>
            <PortfolioModal open={open} onClose={handleClose} content={props.content}/>
        </div>
    )
}

export default PortfolioCard;