import React from 'react'
import '../styles/HeadingComponent.css'

function HeadingComponent(props){
    return(
        <div className={"HeadingComponent-" + props.type}>
            <h1>
                {props.number && <span className="HeadingComponent-number">{props.number}</span>}
                {props.title}
                {props.period && <span className="HeadingComponent-period">.</span>}
            </h1>
            {props.subHeading && <h4>{props.subHeading}</h4>}
        </div>
    )
}

export default HeadingComponent;